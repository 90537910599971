import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import css from './DefaultHeader.desktop.scss';
import defaultCss from '../Header.scss';
import DefaultHeaderContent from './DefaultHeaderContent';
import Logo from '../components/Logo/Logo';
import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';
import { compose } from 'redux';
import withConfig from 'HOCs/withConfig/withConfig';
import PreNotification from 'Components/PreNotification/PreNotification.APP_TARGET';
import { getLogoData } from './logoSizeHelper';
import {
    __FEATURE_AD_UPGRADE
} from 'Constants/monetizers';
import { VARIANTS } from 'Constants/constants';
import { CHOOSE_PACKAGE_LAQUESIS_FEATURE_FLAG } from 'Helpers/laquesis';
import { withExperiment } from 'LaquesisEOL/withExperiment';

export class DefaultHeader extends React.Component {
    static propTypes = {
        router: PropTypes.object,
        params: PropTypes.object,
        isOnListing: PropTypes.bool,
        hideActionButtons: PropTypes.bool,
        searchTerm: PropTypes.string,
        hideSearchBar: PropTypes.bool,
        variant: PropTypes.string,
        loginCallback: PropTypes.func,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        preNotificationAvailable: PropTypes.bool,
        preNotificationCookie: PropTypes.bool,
        hideMultiLang: PropTypes.bool,
        isPaidCampaign: PropTypes.bool,
        isMeetingPage: PropTypes.bool,
        offline: PropTypes.bool,
        categoryID: PropTypes.string,
        isOlxAutosLandingPage: PropTypes.bool,
        showCompareIcon: PropTypes.bool,
        seller_state: PropTypes.string,
        flowStep: PropTypes.string
    };

    static defaultProps = {
        params: {},
        isOnListing: false,
        hideActionButtons: false,
        toggleSorting: () => {},
        loginCallback: () => {},
        hideSearchBar: false,
        hideMultiLang: false,
        isPaidCampaign: false,
        variant: '',
        categoryID: '',
        isMeetingPage: false,
        offline: false,
        isOlxAutosLandingPage: false,
        showCompareIcon: false
    };

    constructor(props) {
        super(props);
        this.isMXCL = props.config.get('olxAutos', 'isMXCL');
        this.isOlxAutosLandingPage = props.isOlxAutosLandingPage;
        this.showAutosLogo = false;
        this.enableNewIndonesiaBranding = props.config.get('enableNewIndonesiaBranding');
        if (this.isOlxAutosLandingPage || this.isMXCL) {
            this.showAutosLogo = true;
        }
        this.theme = props.config.get('theme', 'id');
        this.staticAssetPath = props.config.get('staticAssets') ?? '';
        this.logoAltText = props.config.get('logoAltText') ?? '';
    }

    renderLogo = () => {
        const { isPaidCampaign } = this.props;
        const { logoUrl, logoSize, logoWidth, logoHeight } = getLogoData(this.theme, this.showAutosLogo, this.staticAssetPath, this.enableNewIndonesiaBranding);

        return (
            <Logo
                logoUrl={ logoUrl }
                isOnListing={ this.props.isOnListing }
                logoSize={ logoSize }
                logoWidth={ logoWidth }
                logoHeight={ logoHeight }
                logoAltText={ this.logoAltText }
                isPaidCampaign={ isPaidCampaign }
            />
        );
    };

    render() {
        const {
            params,
            isOnListing,
            hideActionButtons,
            hideMultiLang,
            searchTerm,
            hideSearchBar,
            loginCallback,
            router,
            preNotificationAvailable,
            preNotificationCookie,
            offline,
            isMeetingPage,
            isPaidCampaign,
            categoryID,
            seller_state,
            showCompareIcon,
            flowStep,
            variant
        } = this.props;

        const experimentVariant = params.featureType === __FEATURE_AD_UPGRADE && (variant === VARIANTS.VARIANT_B || variant === VARIANTS.VARIANT_C);

        return (
            <header className={ classNames(defaultCss.header, css.defaultHeader, { [css.newDefaultHeaderFA]: experimentVariant, [css.headerSearchBarHidden]: hideSearchBar, [defaultCss.offline]: offline, [css.shadow]: isPaidCampaign }) } data-aut-id="defaultHeader">
                { router?.getCurrentLocation().pathname === '/'
                    && preNotificationAvailable
                    && !preNotificationCookie
                    && <PreNotification />
                }
                <div className={ classNames(css.headerContainer,
                    { [css.mxcl]: this.showAutosLogo },
                    (preNotificationAvailable && !preNotificationCookie) ? css.withPreNotification : css.withoutPreNotification) }>
                    <div className={ classNames(css.sectionWrapper, { [css.newSectionWrapperFA]: experimentVariant }) }>
                        <DefaultHeaderContent
                            renderLogo={ this.renderLogo }
                            seller_state={ seller_state }
                            searchTerm={ searchTerm }
                            params={ params }
                            isOnListing={ isOnListing }
                            hideSearchBar={ hideSearchBar }
                            loginCallback={ loginCallback }
                            hideActionButtons={ hideActionButtons }
                            config={ this.props.config }
                            categoryID={ categoryID }
                            hideMultiLang={ hideMultiLang }
                            isMeetingPage={ isMeetingPage }
                            showCompareIcon={ showCompareIcon }
                            flowStep={ flowStep }
                            experimentVariant={ experimentVariant }
                        />
                    </div>
                </div>
            </header>
        );
    }
}

export const mapStateToProps = state => ({
    preNotificationAvailable: state.preNotification.preNotificationAvailable,
    preNotificationCookie: state.preNotification.preNotificationCookie
});

export default compose(
    withConfig,
    withRouter,
    withTrack,
    withExperiment(CHOOSE_PACKAGE_LAQUESIS_FEATURE_FLAG),
    connect(mapStateToProps, null)
)(DefaultHeader);
