import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'panamera-react-ui';

import Icon from './Icon';
import css from '../Footer.scss';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';

export const IconsColumn = ({ sections, brand, isMxCl, track, isMobile }) => {
    let socialNetworks = null;
    let mobileApps = null;

    sections.forEach(({ id, title, links }) => {
        if (id === 'social-networks') {
            const networks = links.map((link, index) => {
                function onClick() {
                    if (isMxCl) {
                        track(link, title);
                    }
                }
                const hrefLink = isMobile ? link.hrefMobile || link.href : link.href;

                return (<Link
                    key={ index }
                    className={ `${isMxCl ? css.mxclLinks : css.links}` }
                    to={ hrefLink }
                    onClick={ onClick }
                    data-aut-id={ link.title }
                >
                    {link.iconURL
                        ? <ImageWrapper fileName={ link.iconURL } className={ css.newIconSocialMedia } />
                        : <Icon type="networks" target={ link.title } />
                    }
                </Link>);
            });

            socialNetworks = (
                <div className={ classNames(css.withIcons, css.mxclWithIcons) }>
                    <span className={ classNames(css.title, css.mxclIconTitle) }>{ title }</span>
                    <div className={ classNames(css.linksContainer, css.mxclLinksContainer) }> { networks } </div>
                </div>
            );
        }

        if (id === 'mobile-apps') {
            const apps = links.map((link, index) => {
                const hrefLink = isMobile ? link.hrefMobile || link.href : link.href;

                return (
                    <Link
                        key={ index }
                        className={ css.links }
                        to={ hrefLink }
                    >
                        <Icon type="apps" target={ link.title } brand={ brand } />
                    </Link>
                );
            });

            mobileApps = (
                <div className={ classNames(css.withIcons, css.apps) }>
                    { apps }
                </div>
            );
        }
    });

    return (
        <section className={ classNames(css.section, css.iconSection, { [css.mxclIconSection]: isMxCl }) }>
            { socialNetworks }
            { mobileApps }
        </section>
    );
};

IconsColumn.propTypes = {
    sections: PropTypes.array.isRequired,
    brand: PropTypes.string.isRequired,
    isMxCl: PropTypes.bool,
    track: PropTypes.func.isRequired,
    isMobile: PropTypes.bool

};

IconsColumn.defaulProps = {
    isMxCl: false,
    isMobile: false
};

export default IconsColumn;
